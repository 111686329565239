.main-page {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 24px;
}

.main-content-controls {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.main-content-preview {
  min-width: 210px;
  max-width: 310px;
  width: 100%;
  margin-right: 20px;
  position: relative;
}

.main-content-preview-video {
  z-index: 3;
  position: absolute;
}

.main-content-preview-loader {
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content-preview-background {
  z-index: 1;
  position: absolute;
}

.previews-list {
  display: flex;
  max-width: 750px;
  min-width: 250px;
  justify-content: center;
  row-gap: 16px;
  column-gap: 16px;
  margin-left: 40px;
  /* margin-right: 24px; */
}

.subheader {
  font-weight: 600;
  font-size: 20px;
}

.main-header {
  font-weight: 600;
  font-size: 40px;
  min-width: 275px;
  max-width: 100%;
  margin-top: 16px;
}

.create-button-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.create-from-scratch-button {
  color: #000204 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  display: flex;
  column-gap: 16px;
  padding: 16px !important;
  border-radius: 16px !important;
}
.create-from-scratch-button:hover {
  background-color: #f4f5f7 !important;
}

.main-chat-list {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 24px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* scrollbar-color: transparent transparent; Set the initial color to transparent */
  transition: scrollbar-color 0.5s ease;
}

/* On Webkit browsers (e.g., Chrome, Safari), use the following */
.main-chat-list::-webkit-scrollbar {
  width: 8px;
}

.main-chat-list::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 0.5s ease;
}

/* When scrolling, show the scrollbar */
.main-chat-list:hover {
  scrollbar-color: auto auto; /* Set the color to 'auto' on hover */
}

.main-chat-list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Set the color to a semi-transparent black on hover */
}

.main-send-button {
  /* width: (114px); */
  height: (48px);
  padding: 12px 16px 12px 24px !important;
  border-radius: 24px !important;
  gap: 4px !important;
  background: linear-gradient(262.89deg, #0CB6FF 2.54%, rgba(0, 152, 254, 0) 67.01%),
    linear-gradient(126.39deg, #0066FF 9.25%, #BD00FF 80.94%),
    linear-gradient(0deg, #1EF0AF, #1EF0AF);
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px;
  text-transform: none !important;
}

.main-send-button:disabled {
  background: var(--Gray-300, #E6E7E8) !important;
}

.main-send-button-icon {
  padding: 10px !important;
}

.main-send-button-container {
  margin-top: 2px;
  height: 100% !important;
  max-height: 100% !important;
}

.MuiOutlinedInput-root {
  & fieldset {
    border: none; /* Remove the default border */
  }

  &:hover fieldset {
    border: none; /* Remove the border on hover */
  }

  &.Mui-focused fieldset {
    border: none; /* Remove the border when focused */
  }
}

.main-input .MuiOutlinedInput-root {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-size: 14px;
  line-height: 17.8px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
}

.main-input {
  max-width: 1060px;
  min-width: 320px;
  width: 100%;
  min-height: 64px;
  /* padding: 2px 0px 2px 16px !important; */
  border-radius: 32px;
  gap: 16px;
  display: flex;
  justify-content: center;
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  margin-top: 48px !important;
  margin-bottom: 24px !important;
}

.right-panel {
  padding: 16px;
  background-color: #ffffff;
  /* height: calc(100vh - 120px); */
  height: 100%;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 24px;
  overflow: hidden;
  transition: width 0.3s ease;
}

.right-panel.opened {
  min-width: 250px;
  max-width: 372px;
  width: 100%;
  display: flex;
  overflow-y: auto;
}

.right-panel.closed {
  display: none;
}

.t-main-screen {
  display: flex;
  height: calc(100vh - 130px);
  transition: margin 0.3s ease;
  row-gap: 24px;
}

.t-ChatApp {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  width: 100%;
  flex-grow: 1;
  transition: margin 0.15s ease, width 0.15s ease;
  
  height: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 24px;
}

.t-main-chat {
  flex: 1 1 0%;
  overflow: auto;
  max-width: 1060px;
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  /* height: 100%; */
}

.t-main-input-form {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
  padding-bottom: -24px;
}

.t-main-input {
  max-width: 1060px;
  min-width: 320px;
  width: 100%;
  min-height: 64px;
  /* padding: 2px 0px 2px 16px !important; */
  background-color: #fff;
  border-radius: 32px;
  /* gap: 16px; */
  display: flex;
  justify-content: center;
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  /* margin-top: 48px !important; */
  margin-bottom: 24px !important;
}
