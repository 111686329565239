.message-answer {
  display: flex;
  column-gap: 8px;
  align-items: flex-end;
}

.message-question {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 8px;
  align-items: flex-end;
}

.bubble-answer-message {
  padding: 16px;
  border-radius: 20px 20px 20px 8px;
  border: 1px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    linear-gradient(235.67deg, #4CAAFF 0%, #DE90FF 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
    border: 1px solid #0000000A;
    font-size: 16px;
}

.bubble-answer {
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  max-width: 880px;
  margin-right: 40px;
}

.bubble-question {
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  max-width: 840px;
  padding: 16px;
  border-radius: 20px 20px 8px 20px;
  background: #F4F5F6;
  margin-left: 40px;
}

.bubble-additional-block {
  margin-left: 40px;
  margin-right: 40px;
  max-width: 880px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bubble-adventure-preview-button {
  padding: 11px 16px !important;
  color: var(--Gray-900, #000204) !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 20px !important;
  border: 1px solid var(--Gray-300, #E6E7E8) !important;
  display: flex !important;
  column-gap: 10px;
}

.bubble-create-adventure-button {
  margin-left: 16px !important;
  padding: 11px 16px !important;
  color: var(--Gray-0, #FFF) !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 20px !important;
  display: flex !important;
  column-gap: 10px;
  border: 0px solid var(--Gray-200, #EFF0F1) !important;
  background: linear-gradient(263deg, #0CB6FF 2.54%, rgba(0, 152, 254, 0.00) 67.01%), linear-gradient(126deg, #06F 9.25%, #BD00FF 80.94%);
}
