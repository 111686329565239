.user-panel-open {
  width: 330px;
  transition: width 0.2s;
}

.user-panel-container {
  height: 100%;
  margin-left: 24px;
  margin-left: 24px;
  overflow: hidden;
}

.user-panel-content {
  width: 100%;
  max-width: 300px;
  color: var(--day-gray-700, #5A5E62);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.user-panel-closed {
  width: 0;
  transition: width 0.2s;
}

.user-panel-no-chats {
  text-align: center;
  line-height: 18px;
}
