.publish-adventure-content {
  margin-top: 60px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.publish-adventure-header {
  color: #000408;
  font-weight: 600;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 24px;
}

.social-links-block {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.publish-adventure-button {
  display: flex;
  flex-direction: column;
  row-gap: -2px;
  align-items: center;
}

.publish-adventure-icon-button {
  background: #F4F5F6 !important;
}

.publish-adventure-icon-button:hover {
  background: #E6E7E8 !important;
}