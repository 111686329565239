.main {
  display: block;
}

.unity-game-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: '100%';
  min-width: 200px;
  max-width: 310px;
  height: auto;
  aspect-ratio: 281 / 610;
}

.unity-game-view {
  position: absolute;
  z-index: 3;
};

.unity-game-ui {
  position: absolute;
  z-index: 4;
}

.unity-game-overlay-ui {
  position: absolute;
  pointer-events: none;
  z-index: 4;
}

.unity-game-overlay-text {
  position: absolute;
  pointer-events: none;
  z-index: 5;
}

.unity-game-background {
}

.unity-game-container {
  margin-right: 20px;
  position: relative;
}