.preview-item {
  width: 100%;
  height: 100%;
  border-radius: 20px !important;
  border: 3px solid transparent;
  border-image: 
    linear-gradient(233.8deg, rgba(12, 182, 255, 0.74) 22.46%, rgba(0, 152, 254, 0) 65.54%);
  border-image-slice: 1;
  border-image-source: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F4F5F6 !important;
}

.preview-item:hover {
  background-color: #fbfbfb !important;
}

.phone {
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.phone-content {
  border-radius: 16px;
  z-index: 2;
  margin-left: 1px;
  /* width: calc(100% - 5px); */
}

.preview-active {
  background-color: #ffffff !important;
  box-shadow: 0px 6px 32px 0px rgba(0, 0, 0, 0.16) !important;
}

.preview-border {
  height: 284px;
  min-width: 110px;
  max-width: 220px;
  width: 100%;
  border: 3px solid transparent;
}

.preview-border:hover {
  height: 284px;
  min-width: 110px;
  max-width: 220px;
  width: 100%;
  border-radius: 20px;
  border: 3px solid rgba(200, 200, 200, 0.2);
}

.preview-border-active {
  border: 3px solid transparent;
  border-radius: 20px;
  background: 
    linear-gradient(233.8deg, rgba(12, 182, 255, 0.74) 22.46%, rgba(0, 152, 254, 0) 65.54%),
    linear-gradient(126.39deg, #0066FF 9.25%, #BD00FF 80.94%);

  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.phone-image {
  position: absolute;
  z-index: 1;
}

.phone-icon {
  z-index: 3;
  position: absolute;
  margin-top: 175px;
  border-radius: 50%;
  /* border: 3px solid #ffffff; */
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  color: #000204;
  text-transform: none;
}

.preview-phone {
  min-width: 100px;
  border-radius: 16.6px;
}