.login-window {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.login-title {
  align-self: stretch;
  color: var(--day-gray-900, #000408);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.login-window-input {
  border-radius: 24px;
  border: 0px solid #E6E6E6;
  background: var(--Gray-100, #F4F5F6);
  width: 100%;
}

.login-window.Mui-error {
  border: 2px solid var(--red-500-main, #FF2440);
  background: rgba(255, 36, 64, 0.12);
}

.login-window-content {
  display: flex;
  width: 90%;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.login-window-content .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--red-500-main, #FF2440);
}

.login-window-content .MuiFormHelperText-root.Mui-error {
  color: var(--red-500-main, #FF2440);
}

/* Optional: Change background color */
.login-window-content .MuiOutlinedInput-root.Mui-error {
  border: 2px solid var(--red-500-main, #FF2440);
  background: rgba(255, 36, 64, 0.12);
  border-radius: 24px;
}

.login-window-button.MuiButton-root {
  width: 100%;
  height: 48px;
  font-size: 14px;
  border-radius: 24px;
  text-transform: none;
  color: #282828;
}

.login-window-button-apple.MuiButton-root {
  background: #000;
  color: var(--white, var(--Gray-0, #FFF));
}

.login-window-button-apple.MuiButton-root:hover {
  background: #000;
  color: var(--white, var(--Gray-0, #FFF));
}

.login-window-button-google.MuiButton-root {
  color: var(--day-gray-900, #000408);
  border: 1px solid var(--Gray-300, #E6E7E8);
}

.login-window-button-google.MuiButton-root:hover {
  background: var(--Gray-100, #F4F5F6);
  border: 1px solid var(--Gray-300, #F4F5F6);
}

.login-window-button-login.MuiButton-root {
  color: var(--day-gray-900, #000408);
  font-weight: 600;
  background: var(--caribbean-500-main, #1EF0AF);
}

.login-window-button-login.MuiButton-root:hover {
  color: var(--day-gray-900, #000408);
  font-weight: 600;
  background: var(--caribbean-500-main, #14E6A5);
}

.login-window-button-login.MuiButton-root:disabled {
  color: var(--day-gray-500, #A4A8AC);
  font-weight: 600;
  background: var(--Gray-200, #EFF0F1);
}

.login-window-login-text {
  color: #0085FF;
  font-weight: 600;
}

.login-window-tou-text {
  color: #787878;
  font-size: 11px;
}

.login-window-tou-url {
  color: #000408;
  font-weight: 600;
  text-decoration: none;
}

.login-window-logo {
  margin-top: 4px;
  /* margin-bottom: 16px; */
}

.login-window-error-message {
  color: #FF2440;
  font-size: 12px;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 100%;
}
