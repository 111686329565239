.publish-modal-window {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 96px 0px #0000003D;
  padding: 24px;
  border-radius: 24px;
  border: none !important;
  min-width: 200px;
  min-height: 150px;
  margin-right: 40px;
  outline: none;
}

.modal-window-top {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.publish-modal-paddings {
  margin: 40px;
}
