.cookies-window {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
  padding: 10px;
  z-index: 1300;
  width: calc(100% - 48px);
  max-width: 400px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 24px;
  background: var(--Gray-0, #FFF);
  box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.04);
}

.cookies-window-content {
  display: flex;
  column-gap: 16px;
  color: var(--Gray-800, #2C2D2E);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.cookies-window-url {
  color: #0085FF;
  text-decoration: none;
}

.cookies-window-button {
  display: flex !important;
  padding: 8px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 24px !important;
  color: var(--Gray-900, #000204) !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
.cookies-window-accept {
  background: #1EF0AF !important;
}

.cookies-window-accept:hover {
  background: #0Ee09F !important;
}
