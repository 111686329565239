@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Work+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

html,
#root {
    height: 100%;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body {
  min-width: 375px;
}

.App {
  background-color: #F8F8F8;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.app-header-left {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: 500;
}
