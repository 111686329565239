.adventure-preview-subheader {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: -4px;
  margin-left: 24px;
  margin-right: 12px;
  justify-content: space-between;
  margin-bottom: 24px;
}

.adventure-preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.adventure-preview-tab-list {
  border-radius: 20px !important;
  background: var(--Gray-200, #EFF0F1);
  padding: 4px !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.adventure-preview-tab {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  padding: 8px 12px !important;
  border-radius: 16px !important;
  background: #EFF0F1 !important;
  border: none !important;
  box-shadow: none !important;
  /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08) !important; */
  margin-left: 0px !important;
  margin-right: 1px !important;
  min-height: 65px !important;
  height: 100%;
  width: 100%;
  font-weight: 600 !important;
  text-transform: none !important;
}

.adventure-preview-tab:hover {
  color: rgba(0, 0, 0, 0.87) !important;
  /* background: rgba(255, 255, 255, 0.2) !important; */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08) !important;
}

.adventure-preview-tab.Mui-selected {
  background: #fff !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08) !important;
}

.adventure-preview-description {
  margin-top: 16px;
  color: var(--day-gray-900, #000408);
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
}

.adventure-preview-gameplay {
  margin-top: 16px;
  margin-bottom: 16px;
  min-width: 200px;
  max-width: 310px;
  height: auto;
}

.adventure-preview-close-button {
  padding: 12px !important;
}

.adventure-preview-close-button:hover {
  background: var(--Gray-100, #F4F5F6) !important;
}
