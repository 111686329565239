.sign-in-up-button.MuiButton-root {
  border-radius: 40px !important;
  padding: 11px 16px 11px 16px !important;
  font-weight: 600;
  background-color: #FFFFFF;
  text-transform: none;
  color: #000204;
  display: flex;
  column-gap: 8px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 6px 0px rgba(0, 0, 0, 0.04);
}

.sign-in-up-button.MuiButton-root:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 6px 0px rgba(0, 0, 0, 0.04);
}
